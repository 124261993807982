
import { inject } from '@/inversify';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Currency from '@/modules/common/components/currency.vue';
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import type Day from '@/modules/common/types/day.type';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import type RatesService from '@/modules/rates/rates.service';
import type UserService from '@/modules/user/user.service';

import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import BaseCard from './base-card.vue';

@Component({
    filters: { PercentFilter, PriceFilter },
    components: {
        CalendarEventsContainer,
        CalendarItemWrapper,
        Currency,
        RatesDayScanBtn,
        BaseCard,
    },
})

export default class Available extends Vue {
    @inject(KEY.DocumentFiltersService) documentFiltersService!: DocumentFiltersService;
    @inject(KEY.RatesService) ratesService!: RatesService;
    @inject(RatesAnalysisServiceS) ratesAnalysisService!: RatesAnalysisService;
    @inject(RatesAnalysisFiltersServiceS) ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @inject(KEY.UserService) userService!: UserService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    get comparisonDifference() {
        return this.ratesAnalysisService.getBiggestComparisonDifference(this.day);
    }

    get cardColorClass() {
        const { assessment } = this.comparisonDifference;

        switch (assessment) {
            case ASSESSMENTS_TYPES.GOOD:
                return 'green';
            case ASSESSMENTS_TYPES.NORMAL:
                return 'yellow';
            case ASSESSMENTS_TYPES.BAD:
                return 'red';
            default:
                return 'gray';
        }
    }

    get competitionPercent() {
        return this.comparisonDifference.percent;
    }

    get isHaveCancellation() {
        if (this.ratesAnalysisFiltersService.comparisonKey !== 'diffDays') {
            return false;
        }

        const { comparisonValues } = this.ratesAnalysisFiltersService;

        const percent = this.ratesService
            .getCompetitionPercent(this.day);

        const isSoldOut = comparisonValues.every((_, docIndex) => this.ratesAnalysisService
            .isSoldOut(this.day, this.currentHotelId, undefined, docIndex));

        return (Math.round(Number(percent)) === 0 && isSoldOut) || this.isNoDataComparison;
    }

    get currentHotelId() {
        return Number(this.$route.params.hotelId);
    }

    get isNoDataComparison() {
        return this.ratesAnalysisService
            .isNoData(this.day);
    }
}
